<template>
  <div>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="true"
    />

    <form @submit.prevent="handleSubmit">
      <fieldset>
        <legend class="mb-6 text-lg">Switch QA Mode</legend>
        <div class="mb-3">Email：{{ $route.params.email }}</div>
        <div class="mb-6">
          <label
            for="qa_mode"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >QAMode</label
          >
          <select
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            v-model="formData.EnableQAMode"
          >
            <option value="true">Enabled</option>
            <option value="false">Disabled </option>
          </select>
        </div>

        <div class="mb-6">
          <label
            class="block mb-2 text-sm font-medium text-gray-900 "
            for="api_endpoint"
            >APIEndpoint (optional)</label
          >
          <input
            type="text"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            id="api_endpoint"
            v-model="formData.ApiEndpoint"
            size="50"
          />
        </div>
        <button
          class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
          type="submit"
        >
          Submit
        </button>
      </fieldset>
    </form>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      formData: {
        EnableQAMode: null,
        ApiEndpoint: null,
      },
    };
  },
  watch: {
    userData: {
      handler(val) {
        console.log(val);
        if (val?.UserInfoList) {
          const index = val.UserInfoList.findIndex(
            (item) => item.Email === this.$route.params.email
          );
          if (index !== -1) {
            this.formData = {
              ...val.UserInfoList[index],
              EnableQAMode: !!val.UserInfoList[index].EnableQAMode,
            };
          }
        } else {
          const email = this.$route.params.email;
          if (email) {
            this.searchUser({ email: email });
          }
        }
      },
      immediate: true,
    },
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      userData: 'getUserData',
      isLoading: 'isLoading',
    }),
  },
  methods: {
    ...mapActions({
      onCancel: 'cancelPromise',
      switchQAMode: 'switchQAMode',
      searchUser: 'searchUser',
    }),
    handleSubmit() {
      this.switchQAMode({
        Email: this.formData.Email,
        EnableQAMode: this.formData.EnableQAMode,
        ApiEndpoint: this.formData.ApiEndpoint,
      });
    },
  },
};
</script>
